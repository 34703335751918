import { useCallback, useEffect, useState } from 'react';

import { fg } from '@confluence/feature-gating';
import {
	deserializeState,
	useGetOnboardingState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import { useIsProductAdmin } from '@confluence/current-user';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS } from '../constants/onboardingStateKeys';

const isLicensed = (accessStatus: AccessStatus | null) =>
	accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
	accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

const atLeastTwo = (...features: boolean[]) =>
	features.reduce((count, feature) => count + (feature ? 1 : 0), 0) >= 2;

const useHasEnoughFeatures = () => {
	const { isProductAdmin } = useIsProductAdmin();
	const isNav4Enabled = useIsNav4Enabled();
	return (
		atLeastTwo(
			fg('confluence_frontend_content_wrapper'),
			fg('confluence_live_pages_open_beta_trait_opted_in'),
			isNav4Enabled,
		) || isProductAdmin
	);
};

export const useModernizeModal = () => {
	const [isOpen, setIsOpen] = useState<boolean | null>(null);
	const { accessStatus } = useSessionData();
	const { data, loading, error } = useGetOnboardingState(
		[...Object.values(MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS)],
		!isLicensed(accessStatus) || !fg('cc_onboarding_modernize_confluence_ipm_modal'),
	);

	const { hasDismissedModernizeConfluenceIpmModal } = deserializeState(data);
	const hasEnoughFeatures = useHasEnoughFeatures();
	const { setOnboardingState } = useSetOnboardingState();

	const onInteraction = useCallback(() => {
		if (!hasDismissedModernizeConfluenceIpmModal) {
			setOnboardingState({
				key: MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS.hasDismissedModernizeConfluenceIpmModal,
				value: 'true',
			});
		}
	}, [setOnboardingState, hasDismissedModernizeConfluenceIpmModal]);

	const onClose = () => {
		setIsOpen(false);
		onInteraction();
	};

	// Once the onboarding state has loaded for the first time, we mark the modal as open/closed
	// based on the onboarding state.
	useEffect(() => {
		if (isOpen === null && !loading && !error) {
			setIsOpen(!hasDismissedModernizeConfluenceIpmModal);
		}
	}, [loading, error, hasDismissedModernizeConfluenceIpmModal, isOpen]);

	if (!fg('cc_onboarding_modernize_confluence_ipm_modal')) {
		return {
			isOpen: false,
			onClose: () => {},
			onInteraction: () => {},
		};
	}

	if (!isLicensed(accessStatus)) {
		return {
			isOpen: false,
			onClose: () => {},
			onInteraction: () => {},
		};
	}

	if (!hasEnoughFeatures) {
		return {
			isOpen: false,
			onClose: () => {},
			onInteraction: () => {},
		};
	}

	if (error) {
		return {
			isOpen: false,
			error,
			onClose: () => {},
			onInteraction: () => {},
		};
	}

	if (loading) {
		return {
			isOpen: false,
			loading,
			onClose: () => {},
			onInteraction: () => {},
		};
	}

	return {
		onClose,
		onInteraction,
		isOpen,
	};
};
