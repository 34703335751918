import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { useUpdateNav4OptInQuery as useUpdateNav4OptInQueryType } from './__types__/useUpdateNav4OptInQuery';
import type {
	useUpdateNav4OptInMutation as useUpdateNav4OptInMutationType,
	useUpdateNav4OptInMutationVariables,
	useUpdateNav4OptInMutation_confluence_updateSiteConfiguration_errors as useUpdateNav4OptInErrorType,
} from './__types__/useUpdateNav4OptInMutation';
import { updateNav4AdminOptInCache } from './nav4AdminOptInCache';

export const useUpdateNav4OptIn = ({
	newIsNav4OptedIn,
	onCompleted,
	onError,
}: {
	newIsNav4OptedIn: boolean;
	onCompleted?: () => void;
	onError?: () => void;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [nav4OptInMutation, { loading: mutationLoading, error: mutationError }] = useMutation<
		useUpdateNav4OptInMutationType,
		useUpdateNav4OptInMutationVariables
	>(gql`
		mutation useUpdateNav4OptInMutation($siteConfigInput: ConfluenceUpdateSiteConfigurationInput!) {
			confluence {
				updateSiteConfiguration(input: $siteConfigInput) {
					success
					errors {
						message
					}
				}
			}
		}
	`);

	const [updateNav4OptIn, { loading: queryLoading, error: queryError }] =
		useLazyQuery<useUpdateNav4OptInQueryType>(
			gql`
				query useUpdateNav4OptInQuery {
					confluence {
						siteConfiguration {
							connectionTimeout
							formattingSettings {
								dateFormat
								dateTimeFormat
								decimalNumberFormat
								longNumberFormat
								timeFormat
							}
							globalDefaultLocale {
								name
							}
							indexingLanguage
							isContactAdministratorsFormEnabled
							isEditorConversionForSiteEnabled
							isEditorFullWidthEnabled
							isEmailNotificationEnabled
							isExternalConnectionEnabled
							isLikesEnabled
							isPushNotificationEnabled
							attachmentSettings {
								maxAttachmentSize
								maxAttachmentsPerUpload
							}
							siteTitle
							socketTimeout
						}
					}
				}
			`,
			{
				onCompleted: (siteSettingsConfigData) => {
					nav4OptInMutation({
						variables: {
							// @ts-ignore
							siteConfigInput: {
								isNav4OptedIn: newIsNav4OptedIn,
								// updateSiteConfiguration mutation has required variables
								...getRequiredUpdateSiteConfigVariables(siteSettingsConfigData),
							},
						},
					})
						.then((updateSiteConfigData) => {
							const updateSiteConfiguration =
								updateSiteConfigData?.data?.confluence?.updateSiteConfiguration;
							if (updateSiteConfiguration?.success) {
								updateNav4AdminOptInCache(newIsNav4OptedIn);
								createAnalyticsEvent({
									type: 'sendTrackEvent',
									data: {
										action: 'changed',
										actionSubject: 'navigationVersion',
										source: 'nav4OptIn',
										attributes: {
											originalNavVersion: newIsNav4OptedIn ? '3' : '4',
											newNavVersion: newIsNav4OptedIn ? '4' : '3',
										},
									},
								}).fire();
								onCompleted?.();
							} else {
								// updateSiteConfiguration mutation can return successfully without server errors
								// but have { success: false } in the response. This should be treated as an error
								// as changes were not successfully applied
								handleError(updateSiteConfiguration?.errors);
							}
						})
						.catch((error) => {
							handleError(error);
						});
				},
				onError: (error) => {
					handleError(error);
				},
			},
		);

	const handleError = (error: ApolloError | useUpdateNav4OptInErrorType[] | null | undefined) => {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'changeFailed',
				actionSubject: 'navigationVersion',
				source: 'nav4OptIn',
				attributes: {
					error,
				},
			},
		}).fire();

		onError?.();
	};

	return {
		updateNav4OptIn,
		loading: queryLoading || mutationLoading,
		error: queryError || mutationError,
	};
};

const getRequiredUpdateSiteConfigVariables = (
	updateSiteConfigData: useUpdateNav4OptInQueryType,
) => {
	const siteConfiguration = updateSiteConfigData?.confluence?.siteConfiguration;
	const formattingSettings = siteConfiguration?.formattingSettings;

	return {
		connectionTimeout: siteConfiguration?.connectionTimeout,
		dateFormat: formattingSettings?.dateFormat,
		dateTimeFormat: formattingSettings?.dateTimeFormat,
		decimalNumberFormat: formattingSettings?.decimalNumberFormat,
		globalDefaultLocale: siteConfiguration?.globalDefaultLocale?.name,
		indexingLanguage: siteConfiguration?.indexingLanguage,
		isContactAdministratorsFormEnabled: siteConfiguration?.isContactAdministratorsFormEnabled,
		isEditorConversionForSiteEnabled: siteConfiguration?.isEditorConversionForSiteEnabled,
		isEditorFullWidthEnabled: siteConfiguration?.isEditorFullWidthEnabled,
		isEmailNotificationEnabled: siteConfiguration?.isEmailNotificationEnabled,
		isExternalConnectionsEnabled: siteConfiguration?.isExternalConnectionEnabled,
		isLikesEnabled: siteConfiguration?.isLikesEnabled,
		isPushNotificationEnabled: siteConfiguration?.isPushNotificationEnabled,
		longNumberFormat: formattingSettings?.longNumberFormat,
		maxAttachmentSize: siteConfiguration?.attachmentSettings?.maxAttachmentSize,
		maxNumberOfAttachmentsPerUpload: siteConfiguration?.attachmentSettings?.maxAttachmentsPerUpload,
		siteTitle: siteConfiguration?.siteTitle,
		socketTimeout: siteConfiguration?.socketTimeout,
		timeFormat: formattingSettings?.timeFormat,
	};
};
